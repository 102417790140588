import React, { useEffect, useState } from "react"
import Imgix from "react-imgix"
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import styled from "styled-components"
import { Box, Typography, Grid, Slide, Snackbar } from "@lib/ui/core"
import { getProduct } from "@services/subscription"
import { useStoreState, useStoreActions } from "easy-peasy"
import CardWithSlide from "@components/CardWithSlide"

import VisaIcon from "@assets/images/visa-icon.png"
import TestIcon from "@assets/images/applepay.png"
import MasterIcon from "@assets/images/mastercard-icon.png"
import GooglePayIcon from "@assets/images/google-pay.jpg"

const TopSection = styled(Box)`
  background: radial-gradient(65% 50% at 50% 75%, #ffffff 95%, #67b0ff 95%);
`

const SubscriptionPage = () => {
  const [products, setProducts] = useState(null)
  const [bottomIn, setBottomIn] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [notSubscribed, setNotSubscribed] = useState(false)

  const accessToken = useStoreState(
    ({ localStorage }) => localStorage.auth.accessToken
  )

  const profile = useStoreState(({ user }) => user.profile)

  const initPage = async () => {
    let result = await getProduct()
    const { data } = result
    if (!data.error) {
      // let displayList = data.items.sort(
      //   (a, b) => a.subscription_length - b.subscription_length
      // )
      if (profile && !profile.is_subscriber) {
        setNotSubscribed(true)
      }
      setProducts(data.items)
      setIsLoad(true)
      setBottomIn(true)
    }
  }

  useEffect(() => {
    initPage()
  }, [])

  return (
    <Layout homepage>
      <TopSection>
        <Box display="flex" width="100%">
          <Container maxWidth>
            <Box pt={5} pb={10}>
              <Box align="center" pb={10}>
                <Slide direction="down" timeout={1300} in={true}>
                  <Typography variant="h4" color="textSecondary">
                    月費計劃及產品
                  </Typography>
                </Slide>
              </Box>
              <Box>
                <Grid
                  container
                  spacing={5}
                  align="center"
                  justify="center"
                  direction="row"
                >
                  {products &&
                    products.map((item, index) => (
                      <Grid item xs={12} sm={4}>
                        <CardWithSlide
                          delayTime={1300 + 200 * index}
                          item={item}
                          needLogin
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
              <Box align="center" pt={10} color="#007bff">
                <Slide direction="up" timeout={1300} in={bottomIn}>
                  <Box>
                    <Typography variant="h4">
                      立即加入，感受全新學習模式
                    </Typography>
                    <Typography>
                      支持信用卡、轉數快、Apple Pay、Google
                      Pay、銀行轉帳等付款方式
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      mt={2}
                    >
                      <Box mx={2}>
                        <Imgix src={VisaIcon} height="25px" />
                      </Box>
                      <Box mx={2}>
                        <Imgix src={MasterIcon} height="25px" />
                      </Box>
                      <Box mx={2}>
                        <Imgix src={TestIcon} height="25px" />
                      </Box>
                      <Box mx={2}>
                        <Imgix src={GooglePayIcon} height="25px" />
                      </Box>
                    </Box>
                  </Box>
                </Slide>
              </Box>
            </Box>
          </Container>
        </Box>
      </TopSection>
    </Layout>
  )
}

export default SubscriptionPage
